var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    !_vm.appBj
      ? 'app-container-color'
      : _vm.isLogin
      ? 'app-container-login'
      : 'app-container'
  ],attrs:{"id":"app"}},[_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }