<template>
  <div
    id="app"
    :class="[
      !appBj
        ? 'app-container-color'
        : isLogin
        ? 'app-container-login'
        : 'app-container'
    ]"
  >
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'App',
  computed: {
    ...mapGetters(['sidebarRoutes'])
  },
  data() {
    return {
      appBj: true,
      isLogin: true
    }
  },
  watch: {
    $route: {
      handler(newT) {
        this.$nextTick(() => {
          this.setAppBj(newT.path)
        })
      },
      immediate: true
    }
  },

  mounted() {
    // this.setAppBj(this.$route.path)
  },
  methods: {
    setAppBj(path) {
      if (
        path.split('/').indexOf('home') > -1 ||
        path.split('/').indexOf('subdetail') > -1 ||
        path.split('/').indexOf('404') > -1
      ) {
        this.appBj = false
        this.isLogin = false
      } else {
        this.appBj = true

        if (path == '/login') {
          this.isLogin = true
        } else {
          this.isLogin = false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  background: url('./assets/images/other-bg.png') bottom center no-repeat;
  background-size: 100% 100%;
  // transition: background 0.3s ease-in-out;
}
.app-container-login {
  background: url('./assets/images/login-bg.png') bottom center no-repeat;
  background-size: 100% 100%;
  // transition: background 0.3s ease-in-out;
}

.app-container-color {
  background: #0b0b0e;
  // transition: background 0.3s ease-in-out;
}
</style>
