<template>
  <div class="primary-text-btn ml-4">
    <el-button type="primary" @click="handelPrimaryBtn">
      {{ btnText }}
    </el-button>
  </div>
</template>

<script>
import i18n from '@/lang'
export default {
  name: 'PrimaryTextBtn',
  props: {
    btnText: {
      type: String,
      default: i18n.t('button.ok')
    }
  },
  methods: {
    handelPrimaryBtn() {
      this.$emit('primary')
    }
  }
}
</script>

<style lang="scss" scoped>
.primary-text-btn {
  :deep(.el-button) {
    min-width: 90px !important;
    padding: 0;
    text-align: center;
    height: 36px !important;
    font-size: 14px !important;
  }
}
</style>
