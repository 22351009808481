const getters = {
  language: (state) => state.app.language,
  token: (state) => state.user.token,
  name: (state) => state.user.name,
  avatar: (state) => state.user.avatar,
  roles: (state) => state.user.roles,
  permissions: (state) => state.user.permissions,
  userInfo: (state) => state.user.userInfo,
  sidebarRouters: (state) => state.permission.sidebarRouters,
  allRoutes: (state) => state.permission.allRoutes,
  indexPage: (state) => state.permission.indexPage,
  listLenght: (state) => state.common.listLenght,
  setReportTitle: (state) => state.common.setReportTitle,
  setTitle: (state) => state.common.setTitle,
  setTitleSub: (state) => state.common.setTitleSub,
  setHeaderMent: (state) => state.common.setHeaderMent,
  setFactoryId: (state) => state.common.setFactoryId,
  setFleetId: (state) => state.common.setFleetId,
  setTableLoading: (state) => state.common.setTableLoading,
  setRoleTypeList: (state) => state.common.setRoleTypeList,
  setUserAdminList: (state) => state.common.setUserAdminList,
  setFile: (state) => state.common.setFile,
  setDialogConfirmBtn: (state) => state.common.setDialogConfirmBtn
}
export default getters
