<template>
  <div
    :class="[
      'tbb-list-common-input',
      isShowSvg ? 'tbb-list-input' : 'tbb-search-input'
    ]"
  >
    <el-input
      :placeholder="$t(placeholder)"
      :value="value"
      :type="type"
      @input="$emit('input', $event)"
      @keyup.enter.native="handelEnter"
    >
      <svg-icon v-show="isShowSvg" slot="prefix" icon-class="search"></svg-icon>
    </el-input>
  </div>
</template>

<script>
export default {
  name: 'TbbListInput',
  props: {
    value: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default: 'placeholder.search_list'
    },
    isShowSvg: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    handelEnter() {
      this.$emit('enter', this.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.tbb-list-common-input {
  :deep(.el-input__inner) {
    width: 190px;
    height: 36px;
    background-color: #1e1f22;
    color: #999999;
    line-height: 36px;
    border-radius: 6px;
  }
  :deep(.el-input__inner::placeholder) {
    font-family: HARMONYOS_SANS_REGULAR;
    font-size: 12px;
    color: #999999;
  }
  :deep(.el-input__prefix) {
    font-size: 24px;
  }
}
.tbb-list-input {
  :deep(.el-input__inner) {
    padding-left: 36px;
  }
}
.tbb-search-input {
  :deep(.el-input__inner) {
    padding-left: 16px;
  }
}
</style>
