<template>
  <div class="tbb-collect-aside-container">
    <svg-icon
      icon-class="logo-min"
      class="font-36 cursor-pointer"
      @click="handleClick('/home')"
    />

    <ul
      class="tbb-menu-list mt-40 flex flex-direction-column flex-jc-center flex-ai-center"
    >
      <li
        :class="[
          'tbb-menu-item mb-16 flex flex-jc-center flex-ai-center',
          activePath == item.path ? 'item-active' : ''
        ]"
        v-for="(item, index) in decomposeMenu(sidebarRouters)"
        :key="index"
        @click="handleClick(item.childPath, item.path)"
      >
        <el-tooltip
          class="item"
          effect="dark"
          :content="item.name"
          placement="right"
          v-if="!item.hidden"
        >
          <svg-icon
            :icon-class="item.meta.icon"
            class="font-24 cursor-pointer"
          />
        </el-tooltip>
      </li>
    </ul>

    <p class="collect-version-bottom font-color-gray">
      {{ $t('version.version_num') }}
    </p>
  </div>
</template>

<script>
import path from 'path'
import { isExternal } from '@/utils/validate'

import { mapGetters } from 'vuex'

export default {
  name: 'TbbCollectAside',
  computed: {
    ...mapGetters(['sidebarRouters', 'indexPage'])
  },
  data() {
    return {
      activePath: '',
      menuList: [
        {
          childPath: 'alarm',
          parentPath: 'home'
        },
        {
          childPath: 'detail',
          parentPath: 'home'
        },
        {
          childPath: 'report',
          parentPath: 'report'
        },
        {
          childPath: 'alocation',
          parentPath: 'role'
        },
        ,
        {
          childPath: 'subdetail',
          parentPath: 'list'
        }
      ]
    }
  },
  watch: {
    $route: {
      handler(route) {
        this.setActivePath(route.path)
      },
      immediate: true
    }
  },
  created() {
    this.setActivePath(this.$route.path)
  },

  methods: {
    setActivePath(path) {
      let indexP = this.indexPage.split('/')[1]
      let otherP = this.setOtherP(path)

      if (indexP != otherP) {
        this.activePath = otherP
      } else {
        this.activePath = '/' + indexP
      }
    },
    setOtherP(path) {
      let pLength = path.split('/')
      if (pLength.length > 3) {
        return this.setDefaultActiveFun(path)
      } else {
        if (pLength.length <= 2) {
          return pLength[1]
        } else {
          return pLength[2]
        }
      }
    },
    setDefaultActiveFun(path) {
      let newPath = ''
      this.menuList.map((item) => {
        if (item.childPath == path.split('/')[2]) {
          newPath = item.parentPath
        }
      })
      return newPath
    },
    handleClick(childPath, path) {
      this.$store.dispatch('setReportTitle', '')
      this.$store.dispatch('listLenght', 0)
      this.activePath = path
      this.$router.push(childPath)
      sessionStorage.removeItem('detail-active')
      sessionStorage.removeItem('fleetId', this.fleetId)
    },
    decomposeMenu(list) {
      let routeList = []
      list.map((item) => {
        if (item.children && item.children.length > 0 && !item.hidden) {
          item.children.map((child) => {
            routeList.push({
              ...child,
              childPath: this.resolvePath(child.path, item.path)
            })
          })
        } else {
          routeList.push({ ...item, childPath: this.resolvePath(item.path) })
        }
      })

      return routeList
    },
    resolvePath(routePath, routeParent) {
      if (isExternal(routePath)) {
        return routePath
      }
      if (isExternal(routeParent)) {
        return routeParent
      }

      return routeParent
        ? path.resolve(routeParent, routePath)
        : path.resolve('/' + routePath)
    }
  }
}
</script>

<style lang="scss" scoped>
.tbb-collect-aside-container {
  height: 100%;
  position: fixed;
  z-index: 200;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0);
  backdrop-filter: blur(20px); /* 背景模糊 */
  -webkit-backdrop-filter: blur(20px); /* 兼容老版本 Safari */

  border-right: 1px solid rgba(112, 112, 112, 0.35);
  padding: 20px 12px;

  .tbb-menu-list {
    .item-active {
      width: 34px;
      height: 34px;
      background: #35353a;
      border-radius: 4px 4px 4px 4px;
    }
  }
  .collect-version-bottom {
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
  }
}
</style>
