import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from '@/utils/auth'

NProgress.configure({ showSpinner: false })

const whiteList = ['/share', '/login', '/register', '/404']

router.beforeEach((to, from, next) => {
  NProgress.start()
  if (from.name == 'ShareDetail' && to.path == whiteList[0]) {
    //
  } else {
    if (getToken() && to.path != whiteList[0]) {
      to.meta.title && store.dispatch('setTitle', to.meta.title)

      if (to.path === '/login') {
        next({ path: '/' })
        NProgress.done()
      } else {
        if (store.getters.roles.length === 0) {
          // 判断当前用户是否已拉取完user_info信息
          store
            .dispatch('GetInfo')
            .then((resI) => {
              if (resI) {
                store.dispatch('GenerateRoutes').then((accessRoutes) => {
                  // 根据roles权限生成可访问的路由表
                  // console.info('这是路由表', accessRoutes)
                  if (accessRoutes.length > 0) {
                    const isChild =
                      accessRoutes[0].children &&
                      accessRoutes[0].children.length > 0
                    store.dispatch(
                      'setTitle',
                      isChild
                        ? accessRoutes[0].children[0].meta.title
                        : accessRoutes[0].meta.title
                    )
                    router.addRoutes(accessRoutes) // 动态添加可访问路由表
                    next({ ...to, replace: true })
                    NProgress.done()
                  } else {
                    next({ path: '/setting/set' })
                    NProgress.done()
                  }
                })
              } else {
                next({ ...to, replace: true })
                NProgress.done()
              }
            })
            .catch((err) => {
              store.dispatch('LogOut').then(() => {
                next({ path: '/login' })
              })
            })
        } else {
          next()
          NProgress.done()
        }
      }
    } else {
      // 没有token
      if (whiteList.indexOf(to.path) !== -1) {
        // 在免登录白名单，直接进入

        if (to.path == whiteList[0]) {
          store.dispatch('LogOut').then(() => {
            store.dispatch('GetShareUserInfo', to.query.tk).then((res) => {
              if (res.password) {
                store.dispatch('Login', res).then((resp) => {
                  if (resp == 200) {
                    store.dispatch('GetInfo').then((resI) => {
                      next({ path: '/share/detail/page' })
                      NProgress.done()
                    })
                  }
                })
              } else {
                next()
                NProgress.done()
              }
            })
          })
        } else {
          next()
          NProgress.done()
        }
      } else {
        next(`/login`) // 否则全部重定向到登录页
        NProgress.done()
      }
    }
  }
})

router.afterEach(() => {
  let scrollContent = document.querySelector('.scroll-content')

  if (scrollContent) {
    scrollContent.scrollTop = 0
    scrollContent.scrollLeft = 0
  }
  NProgress.done()
})
