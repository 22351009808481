<template>
  <div class="lightblue-text-btn ml-8">
    <el-button type="lightblue" @click="handelPrimaryBtn">
      {{ btnText }}
    </el-button>
  </div>
</template>

<script>
import i18n from '@/lang'
export default {
  name: 'LightBlueTextBtn',
  props: {
    btnText: {
      type: String,
      default: i18n.t('button.ok')
    }
  },
  methods: {
    handelPrimaryBtn() {
      this.$emit('lightblue')
    }
  }
}
</script>

<style lang="scss" scoped>
.lightblue-text-btn {
  :deep(.el-button) {
    min-width: 90px !important;
    padding: 0;
    text-align: center;
    height: 36px !important;
    font-size: 14px !important;
  }
}
</style>
