import request from '@/utils/request'

// 登录方法
export function login(data) {
  return request({
    url: '/oauth/token',
    headers: {
      isToken: false
    },

    method: 'post',
    auth: {
      username: 'tbb-iov-tbbfleet-web',
      password: 'iovtbbfleetweb213465'
    },
    data: data
  })
}

// 注册方法
export function register(data) {
  return request({
    url: '/user/v1/register',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/customization/user/v1/query/info',
    method: 'get'
  })
}

// 获取验证码
export function getCode(data) {
  return request({
    url: '/user/v1/send/code',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 用户忘记密码修改
export function forgetPassword(data) {
  return request({
    url: '/user/v1/forget/password/update',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}
