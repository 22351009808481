import request from '@/utils/request'

export function getShareInfo(data) {
  return request({
    url: '/customization/share/v1/get/info',
    method: 'get',
    params: data
  })
}

export function getShareInfoVehicle(data) {
  return request({
    url: '/customization/share/v1/get/info/vehicle',
    method: 'get',
    params: data
  })
}

export function addSharesingle(data) {
  return request({
    url: '/customization/share/v1/add/single',
    method: 'post',
    data: data
  })
}

export function updateSharesingle(data) {
  return request({
    url: '/customization/share/v1/update/single',
    method: 'put',
    data: data
  })
}

export function deleteSharesingle(data) {
  return request({
    url: '/customization/share/v1/delete/single',
    method: 'delete',
    params: data
  })
}
