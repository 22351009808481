<template>
  <div>
    <OperationDialog
      :show="showDialog"
      :opera-info="operaInfo"
      :show-content="false"
      :cancel="false"
      :show-close="false"
      @confirm="handelConfirm"
    />
  </div>
</template>

<script>
import OperationDialog from '@/components/TbbDialog/OperationDialog.vue'

export default {
  name: 'PlugDialog',
  components: {
    OperationDialog
  },
  data() {
    return {
      showDialog: true,
      operaInfo: {
        title: 'dialog.attention',
        otherDec: 'error.error_login_token'
      }
    }
  },
  methods: {
    handelConfirm() {
      this.showDialog = false
      this.$store.dispatch('LogOut').then(() => {
        location.href = '/login'
        this.$store.dispatch('setDialogConfirmBtn', true)
      })
    }
  }
}
</script>
