import Vue from 'vue'
import i18n from '@/lang'
import store from '@/store'
import PlugDialog from './index.vue'

let instance = null
let DialogTip = () => {
  // 用Vue.extend 创建组件的模板（构造函数）

  let PlugConstructor = Vue.extend(PlugDialog)

  // 实例化组件,构造函数可以传参 data, method

  instance = new PlugConstructor({
    i18n,
    store
  })

  // 挂载组件到页面上

  instance.$mount()

  document.body.appendChild(instance.$el)
}

export default DialogTip
