export function filterToFixed2(val) {
  let valN = Number(val)
  let valN2 = 0

  if (valN) {
    valN2 = valN.toFixed(2)
  } else {
    valN2 = 0
  }

  return Number(valN2)
}
