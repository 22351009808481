import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'
const WsKey = 'Admin-Ws'

export function getToken() {
  return sessionStorage.getItem(TokenKey)
}

export function setToken(token) {
  return sessionStorage.setItem(TokenKey, token)
}

export function removeToken() {
  return sessionStorage.removeItem(TokenKey)
}

export function getWs() {
  return sessionStorage.getItem(WsKey)
}

export function setWs(ws) {
  return sessionStorage.setItem(WsKey, ws)
}

export function removeWs() {
  return sessionStorage.removeItem(WsKey)
}
