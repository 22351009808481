import Cookies from 'js-cookie'

const state = {
  language: 'en'
}

const mutations = {
  SET_LANGUAGE: (state, language) => {
    state.language = 'en'
  }
}

const actions = {
  setLanguage({ commit }, language) {
    commit('SET_LANGUAGE', language)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
