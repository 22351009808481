<template>
  <div class="tbb-pagination flex flex-jc-between flex-ai-center">
    <p class="ml-36 font-12 font-color-666 flex flex-ai-center">
      <span class="mr-4" v-if="isVehicleId">
        {{ $t('list.common.vehicle') }} {{ vehicleId }}
        <el-divider direction="vertical"></el-divider>
      </span>
      {{ Math.ceil(total / 10) }} {{ $t('list.common.pageTotal') }},
      {{ $t('list.common.currentPage') }} {{ currentN }}
    </p>
    <el-pagination
      @current-change="handleCurrentChange"
      :current-page.sync="currentN"
      :page-size="pageSize"
      layout="prev, pager, next"
      :total="total"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  name: 'TbbPagination',
  props: {
    pageNum: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 10
    },
    total: {
      type: Number,
      default: 0
    },
    vehicleId: {
      type: [Number, String],
      default: 0
    },
    isVehicleId: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentN: 1
    }
  },
  watch: {
    pageNum: {
      handler(valP) {
        this.currentN = valP
      },
      immediate: true
    }
  },
  methods: {
    handleCurrentChange(val) {
      this.$emit('changePageNum', val)
    }
  }
}
</script>

<style lang="scss" scoped>
.tbb-pagination {
  .el-pagination {
    margin-top: 26px;
    :deep(.el-pager) {
      margin: 0 10px;
      li {
        width: 36px;
        height: 36px;
        background: #1a1b1d;
        border-radius: 4px;
        font-family: HARMONYOS_SANS_MEDIUM;
        font-size: 14px;
        color: #999999;
        line-height: 36px;
        margin-right: 6px;
        &:last-of-type {
          margin-right: 0px;
        }
      }
      .active {
        background: #454545;
        color: #fff;
      }
    }
    :deep(.btn-prev) {
      width: 36px;
      height: 36px;
      background: #1a1b1d;
      border-radius: 4px;
      padding: 0px;
      color: #999999;
      &:hover {
        color: #fff;
      }
      i {
        font-size: 18px;
      }
    }
    :deep(.btn-next) {
      width: 36px;
      height: 36px;
      background: #1a1b1d;
      border-radius: 4px;
      padding: 0px;
      color: #999999;

      &:hover {
        color: #fff;
      }
      i {
        font-size: 18px;
      }
    }
  }
}
</style>
