<template>
  <div class="footer-box flex flex-jc-between flex-ai-center">
    <p class="footer-text">
      <span>{{ $t('footer.copy_ltd') }}</span>
      <a href="https://beian.miit.gov.cn">{{ $t('footer.icp') }}</a>
    </p>

    <p class="footer-text">{{ $t('footer.ltd') }}</p>
  </div>
</template>

<script>
export default {
  name: 'TbbFooter'
}
</script>

<style lang="scss" scoped>
.footer-box {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 40px;
  padding: 0 20px;
  width: 100%;
  background-color: #0b0b0e;

  .footer-text {
    color: #999;
    font-size: 10px;
  }
  a {
    color: #999;
    text-decoration: auto;
  }
}
</style>
