/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return path ? /^(https?:|mailto:|tel:)/.test(path) : ''
}

/**
 * 参数处理
 * @param {*} params  参数
 */
export function tansParams(params) {
  let result = ''
  for (const propName of Object.keys(params)) {
    const value = params[propName]
    var part = encodeURIComponent(propName) + '='
    if (value !== null && typeof value !== 'undefined') {
      if (typeof value === 'object') {
        for (const key of Object.keys(value)) {
          if (value[key] !== null && typeof value[key] !== 'undefined') {
            let params = propName + '[' + key + ']'
            var subPart = encodeURIComponent(params) + '='
            result += subPart + encodeURIComponent(value[key]) + '&'
          }
        }
      } else {
        result += part + encodeURIComponent(value) + '&'
      }
    }
  }
  return result
}

//数字转字符串
export function numberToString(value) {
  return value.toString()
}

//字符串转数字
export function stringToNumber(value) {
  return parseInt(value)
}
