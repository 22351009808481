<template>
  <div class="layout-container">
    <el-container>
      <el-aside class="tbb-collect-aside" v-if="isShowCollect">
        <TbbCollectAside />
      </el-aside>

      <el-aside class="tbb-aside" v-else>
        <TbbAside />
      </el-aside>

      <el-container class="tbb-container">
        <el-header>
          <TbbHeader />
        </el-header>
        <el-main class="scroll-content">
          <transition name="fade-transform" mode="out-in">
            <router-view :key="this.$route.path" />
          </transition>
        </el-main>
        <el-footer v-show="isShowFooter">
          <TbbFooter />
        </el-footer>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import TbbAside from './components/TbbAside'
import TbbCollectAside from './components/TbbCollectAside'
import TbbHeader from './components/TbbHeader'
import TbbFooter from '@/components/TbbFooter'
export default {
  name: 'Layout',
  components: { TbbAside, TbbHeader, TbbCollectAside, TbbFooter },
  data() {
    return {
      screenWidth: 0,
      isShowCollect: false,
      isXScoll: false,
      isShowFooter: true,
      noShowPath: [
        '/home',
        '/vehicles/subdetail/page',
        '/home/detail/page',
        '/share/detail/page'
      ]
    }
  },
  watch: {
    $route: {
      handler(route) {
        this.isShowFooter = this.noShowPath.indexOf(route.path) == -1
      },
      immediate: true
    },
    screenWidth: {
      handler(val, oldVal) {
        if (val < 1200) {
          this.isShowCollect = true
        } else {
          this.isShowCollect = false
        }

        if (val < 1024) {
          this.isXScoll = true
        } else {
          this.isXScoll = false
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.screenWidth = document.body.clientWidth
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth
      })()
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-container {
  height: 100%;
  width: 100%;
  position: relative;
}
.tbb-container {
  position: relative;
}
.el-container {
  width: 100%;
  height: 100%;
}

.tbb-aside {
  width: 200px !important;
  height: 100%;
  background: rgba(255, 255, 255, 0);
  backdrop-filter: blur(20px); /* 背景模糊 */
  -webkit-backdrop-filter: blur(20px); /* 兼容老版本 Safari */
  border-right: 1px solid rgba(112, 112, 112, 0.35);
  padding: 20px 20px 0 20px;
}
.tbb-collect-aside {
  width: 60px !important;
  height: 100%;
}
</style>
