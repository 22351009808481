const common = {
  state: {
    listLenght: 0,
    setReportTitle: sessionStorage.getItem('report-title') || '',
    setTitle: '',
    setTitleSub: '',
    setHeaderMent: [],
    setFactoryId: 0,
    setFleetId: 0,
    setTableLoading: true,
    setRoleTypeList: [],
    setUserAdminList: [],
    setFile: null,
    setDialogConfirmBtn: false
  },

  mutations: {
    SET_LIST_LENGTHT: (state, listLenght) => {
      state.listLenght = listLenght
    },
    SET_REPORT_TITLE: (state, setReportTitle) => {
      state.setReportTitle = setReportTitle
    },
    SET_TITLE: (state, setTitle) => {
      state.setTitle = setTitle
    },
    SET_TITLE_SUB: (state, setTitleSub) => {
      state.setTitleSub = setTitleSub
    },
    SET_HEADER_MENT: (state, setHeaderMent) => {
      state.setHeaderMent = setHeaderMent
    },
    SET_FACTORY_ID: (state, setFactoryId) => {
      state.setFactoryId = setFactoryId
    },
    SET_FLEET_ID: (state, setFleetId) => {
      state.setFleetId = setFleetId
    },
    SET_TABLE_LOADING: (state, setTableLoading) => {
      state.setTableLoading = setTableLoading
    },
    SET_ROLE_TYPE_LIST: (state, setRoleTypeList) => {
      state.setRoleTypeList = setRoleTypeList
    },
    SET_USER_ADMIN_LIST: (state, setUserAdminList) => {
      state.setUserAdminList = setUserAdminList
    },
    SET_FILE: (state, setFile) => {
      state.setFile = setFile
    },
    SET_DIALOG_CONFIRM_BTN: (state, setDialogConfirmBtn) => {
      state.setDialogConfirmBtn = setDialogConfirmBtn
    }
  },
  actions: {
    listLenght({ commit }, data) {
      commit('SET_LIST_LENGTHT', data)
    },

    setReportTitle({ commit }, data) {
      commit('SET_REPORT_TITLE', data)
    },
    setTitle({ commit }, data) {
      commit('SET_TITLE', data)
    },
    setTitleSub({ commit }, data) {
      commit('SET_TITLE_SUB', data)
    },
    setHeaderMent({ commit }, data) {
      commit('SET_HEADER_MENT', data)
    },
    setFactoryId({ commit }, data) {
      commit('SET_FACTORY_ID', data)
    },
    setFleetId({ commit }, data) {
      commit('SET_FLEET_ID', data)
    },
    setTableLoading({ commit }, data) {
      commit('SET_TABLE_LOADING', data)
    },
    setRoleTypeList({ commit }, data) {
      commit('SET_ROLE_TYPE_LIST', data)
    },
    setUserAdminList({ commit }, data) {
      commit('SET_USER_ADMIN_LIST', data)
    },
    setFile({ commit }, data) {
      commit('SET_FILE', data)
    },
    setDialogConfirmBtn({ commit }, data) {
      commit('SET_DIALOG_CONFIRM_BTN', data)
    }
  }
}

export default common
