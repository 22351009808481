import Vue from 'vue'

import TbbInput from '@/components/TbbInput'
import BaseDialog from '@/components/TbbDialog'
import OperationDialog from '@/components/TbbDialog/OperationDialog'

import TbbPagination from '@/components/TbbPagination'

import TbbListInput from '@/components/TbbListInput'
import SetIconBtn from '@/components/TbbButton/SetIconBtn'
import PrimaryTextBtn from '@/components/TbbButton/PrimaryTextBtn'
import LightBlueTextBtn from '@/components/TbbButton/LightBlueTextBtn'

Vue.component('TbbInput', TbbInput)
Vue.component('BaseDialog', BaseDialog)
Vue.component('TbbPagination', TbbPagination)
Vue.component('OperationDialog', OperationDialog)
Vue.component('TbbListInput', TbbListInput)
Vue.component('SetIconBtn', SetIconBtn)
Vue.component('PrimaryTextBtn', PrimaryTextBtn)
Vue.component('LightBlueTextBtn', LightBlueTextBtn)
